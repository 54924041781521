<script setup>
import { ref } from "vue";
import { useElementVisibility } from "@vueuse/core";
import AppAccordion from "./AppAccordion";
import clientConfig from "/src/config/client";
import TeamMember from "./TeamMember.vue";

import { onMounted } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});

const FAQ = ref(null);
const scrolledToFAQ = useElementVisibility(FAQ);
const contacts = ref(null);
const scrolledToContacts = useElementVisibility(contacts);

const teamMembers = ref(clientConfig.content.teamMembers);
</script>

<template>
  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top">
    <div class="container d-flex justify-content-between">
      <a href="/" class="logo mr-auto" aria-label="Visit homepage"></a>
      <nav class="nav-menu">
        <ul>
          <li>
            <router-link to="/#hero">Home</router-link>
          </li>
          <li>
            <router-link to="/faq#faq" :class="scrolledToFAQ ? 'active' : ''"
              >FAQs</router-link
            >
          </li>

          <li>
            <router-link
              to="/faq#footer"
              :class="scrolledToContacts ? 'active' : ''"
              >Contact</router-link
            >
          </li>
        </ul>
      </nav>
      <!-- .nav-menu -->
    </div>
  </header>
  <!-- End Header -->

  <main id="main">
    <!-- ======= Faq Section ======= -->
    <section id="faq" class="faq mt-5" ref="FAQ">
      <div class="container mt-5" data-aos="fade-up" data-aos-delay="200">
        <div class="section-title mt-5">
          <h2>Frequently Asked Questions</h2>
        </div>
        <div class="row content">
          <div class="col-lg-6">
            <div class="row m-0">
              <app-accordion class="mb-4">
                <template v-slot:title>
                  <span class="font-semibold text-xl"
                    >How do I join the company super fund?</span
                  >
                </template>
                <template v-slot:content>
                  <p>
                    Visit the <b>documents section</b> on the home page and
                    choose your relevant join the fund form.
                  </p>
                </template>
              </app-accordion>
            </div>

            <div class="row m-0">
              <app-accordion class="mb-4">
                <template v-slot:title>
                  <span class="font-semibold text-xl"
                    >What are my fees and insurance benefits?
                  </span>
                </template>
                <template v-slot:content>
                  <p>
                    By joining the company Superannuation fund, you benefit from
                    <b>group discount rates on fees and insurances</b>. This
                    means you may pay less than you would in a personal or
                    individual plan. Take a look at the About the Company Fund
                    form within the Super Info section.
                  </p>
                </template>
              </app-accordion>
            </div>

            <div class="row m-0">
              <app-accordion class="mb-4">
                <template v-slot:title>
                  <span class="font-semibold text-xl"
                    >How do I increase or decrease my insurance benefit?
                  </span>
                </template>
                <template v-slot:content>
                  <p>
                    Complete the <b>Insurance Application</b> within the
                    Insurances section or contact Gallagher for help.
                  </p>
                </template>
              </app-accordion>
            </div>

            <div class="row m-0">
              <app-accordion class="mb-4">
                <template v-slot:title>
                  <span class="font-semibold text-xl"
                    >Are there alternative investment options?</span
                  >
                </template>
                <template v-slot:content>
                  <p>
                    There are multiple investment options within your Employer
                    Super plan. Refer to the <b>Investment Selection</b> PDF in
                    the Documents section or you can contact us for more info.
                  </p>
                </template>
              </app-accordion>
            </div>

            <div class="row m-0">
              <app-accordion class="mb-4">
                <template v-slot:title>
                  <span class="font-semibold text-xl"
                    >How can I consolidate my super accounts?</span
                  >
                </template>
                <template
                  v-slot:content
                  v-if="clientConfig.content.general.ampFund"
                >
                  <p>
                    <b>Before you do consolidate</b> your super, there are some
                    important things to consider, including:
                  </p>
                  <ol>
                    <li>
                      Whether you want to keep any of the features and benefits
                      you currently have in your other super funds to make sure
                      you're not losing anything important to you
                    </li>
                    <li>
                      Whether you have insurance cover attached to your other
                      super funds, the insurance will be cancelled if you
                      consolidate into one account. So you’ll need to carefully
                      consider whether you have enough insurance cover for your
                      needs without it.
                    </li>
                  </ol>
                  <p><b>Ready to consolidate?</b></p>
                  <ol>
                    <li>
                      complete an <b>ATO Rollover Form</b> found in the
                      Documents section
                    </li>
                  </ol>
                  <p>OR</p>
                  <ul>
                    <li>
                      Login or register for
                      <a
                        href="https://www.amp.com.au/amp/info/access-accounts-online-and-on-the-go"
                        target="_blank"
                        >My AMP</a
                      >
                    </li>
                    <li>Follow the search for my super link</li>
                    <li>
                      Complete a quick security check (This is required by the
                      government– you'll need two types of ID, like your
                      licence, passport or Medicare number)
                    </li>
                    <li>
                      Provide consent for the use of your Tax File Number.
                    </li>
                    <li>Get your results in My AMP.</li>
                  </ul>
                  <p>OR</p>
                  <ul>
                    <li>
                      Go to
                      <a href="https://my.gov.au/" target="_blank">my.gov.au</a>
                    </li>
                    <li>Log in or create an account</li>
                    <li>Link your myGov account to the ATO</li>
                    <li>Select 'Super' and then ‘Manage’</li>
                    <li>
                      Select “transfer Super” (this option will only appear if
                      you have more than one super account)
                    </li>
                  </ul>
                </template>
                <template
                  v-slot:content
                  v-else-if="clientConfig.content.general.notAmpFund"
                >
                  <p>
                    <b>Before you do consolidate</b> your super, there are some
                    important things to consider, including:
                  </p>
                  <ol>
                    <li>
                      Whether you want to keep any of the features and benefits
                      you currently have in your other super funds to make sure
                      you're not losing anything important to you
                    </li>
                    <li>
                      Whether you have insurance cover attached to your other
                      super funds, the insurance will be cancelled if you
                      consolidate into one account. So you’ll need to carefully
                      consider whether you have enough insurance cover for your
                      needs without it.
                    </li>
                  </ol>
                  <p><b>Ready to consolidate?</b></p>
                  <ul>
                    <li>
                      complete an <b>ATO Rollover Form</b> found in the
                      Documents section
                    </li>
                  </ul>
                  <p>OR</p>
                  <ul>
                    <li>
                      Go to
                      <a href="https://my.gov.au/" target="_blank">my.gov.au</a>
                    </li>
                    <li>Log in or create an account</li>
                    <li>Link your myGov account to the ATO</li>
                    <li>Select 'Super' and then ‘Manage’</li>
                    <li>
                      Select “transfer Super” (this option will only appear if
                      you have more than one super account)
                    </li>
                  </ul>
                </template>
                <template v-slot:content v-else>
                  <p>
                    Complete an <b>ATO Rollover Form</b> found in the Documents
                    section.
                  </p>
                </template>
              </app-accordion>
            </div>

            <div class="row m-0">
              <app-accordion class="mb-4">
                <template v-slot:title>
                  <span class="font-semibold text-xl"
                    >How can I find my lost Super?</span
                  >
                </template>
                <template v-slot:content>
                  <p>
                    You can check your super by registering for the
                    <b>Australian Taxation Office’s online services</b> via
                    <a
                      href="https://my.gov.au/LoginServices/main/login?execution=e2s1"
                      target="_blank"
                      >myGov</a
                    >. This will allow you to see details of all super accounts
                    including any lost accounts.
                  </p>
                  <ul>
                    <li>
                      Go to
                      <a
                        href="https://my.gov.au/LoginServices/main/login?execution=e2s1"
                        target="_blank"
                        >my.gov.au</a
                      >
                    </li>
                    <li>Log in or create an account</li>
                    <li>Link your myGov account to the ATO</li>
                    <li>Select 'Super' and then 'Manage'</li>
                    <li>
                      Select “transfer Super” (this option will only appear if
                      you have more than one super account)
                    </li>
                  </ul>
                  <p>
                    This will show you all of your super accounts and let you
                    transfer your balance from one to another.
                  </p>
                  <p>
                    If you've recently opened a new super account, it may take
                    up to six months to appear on myGov.
                  </p>
                  <p>
                    You can also find lost super using a paper form. See
                    <a
                      href="https://www.ato.gov.au/forms/searching-for-lost-super/"
                      target="_blank"
                      >searching for lost super</a
                    >
                    on the ATO website.
                  </p>
                </template>
              </app-accordion>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="row m-0">
              <app-accordion class="mb-4">
                <template v-slot:title>
                  <span class="font-semibold text-xl"
                    >What happens if I change employers?</span
                  >
                </template>
                <template v-slot:content>
                  <p>
                    Complete the <b>ATO Standard Choice</b> form in the
                    Documents section and click Join the Company Fund. Then give
                    the form to your new employer and they can start putting
                    your Super Contributions into your super account. In some
                    cases, fees and insurance premiums may be impacted. Call
                    Gallagher for more info.
                  </p>
                </template>
              </app-accordion>
            </div>

            <div class="row m-0">
              <app-accordion class="mb-4">
                <template v-slot:title>
                  <span class="font-semibold text-xl"
                    >How much super will I need in retirement?</span
                  >
                </template>
                <template v-slot:content>
                  <p>
                    This will vary depending on when you plan to retire, your
                    life expectancy and the kind of retirement lifestyle you’d
                    like to achieve. If you would like to
                    <b>discuss retirement planning</b>, Gallagher has a friendly
                    and experienced personal advice services team. Contact us to
                    find out more.
                  </p>
                </template>
              </app-accordion>
            </div>

            <div class="row m-0">
              <app-accordion class="mb-4">
                <template v-slot:title>
                  <span class="font-semibold text-xl"
                    >What is salary sacrifice?</span
                  >
                </template>
                <template v-slot:content>
                  <p>
                    <b>Salary sacrifice</b> is adding to your super from your
                    pre-tax salary. This can be a smart and tax-effective way to
                    boost your super savings. Contact us if you would like
                    further information.
                  </p>
                </template>
              </app-accordion>
            </div>

            <div class="row m-0">
              <app-accordion class="mb-4">
                <template v-slot:title>
                  <span class="font-semibold text-xl"
                    >I'm retiring soon, can Gallagher help?</span
                  >
                </template>
                <template v-slot:content>
                  <p>
                    Gallagher has a
                    <b>friendly and experienced personal advice services team</b
                    >. Contact us to find out more.
                  </p>
                </template>
              </app-accordion>
            </div>

            <div class="row m-0">
              <app-accordion class="mb-4">
                <template v-slot:title>
                  <span class="font-semibold text-xl text-left"
                    >How do I ensure my superannuation goes to the right person
                    if something happens to me?</span
                  >
                </template>
                <template v-slot:content>
                  <p>
                    Please complete a <b>Death Nomination form</b> located
                    within the Documents Section, or call Gallagher for more
                    info.
                  </p>
                </template>
              </app-accordion>
            </div>

            <div class="row m-0">
              <app-accordion class="mb-4">
                <template v-slot:title>
                  <span class="font-semibold text-xl"
                    >At what age can I access Super?</span
                  >
                </template>
                <template v-slot:content>
                  <p>
                    Other than in a few exceptional circumstances,
                    <b
                      >you cannot access your super until you reach your
                      preservation age and have stopped working</b
                    >. For more information refer to:
                    <a
                      href="https://www.ato.gov.au/super/self-managed-super-funds/paying-benefits/preservation-of-super/"
                      target="_blank"
                      >www.ato.gov.au/super/self-managed-super-funds/paying-benefits/preservation-of-super/</a
                    >
                  </p>
                  <p>
                    If you would like to discuss retirement planning, Gallagher
                    has a friendly and experienced personal advice services
                    team. Contact us to find out more.
                  </p>
                </template>
              </app-accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- =======  FAQ Section end =======  -->
    <!-- ======= Team Section ======= -->
    <section id="team" class="team section-bg page-section">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>Your Gallagher Team</h2>
          <p>
            Your dedicated account management team are always ready to help. You
            can reach us in any of the following ways;
          </p>
        </div>

        <div class="row d-flex justify-content-center">
          <TeamMember
            v-for="teamMember in teamMembers"
            :key="teamMember"
            :teamMemberPic="teamMember.teamMemberPic"
            :name="teamMember.name"
            :role="teamMember.role"
            :phoneHref="teamMember.phoneHref"
            :phone="teamMember.phone"
            :email="teamMember.email"
            :social="teamMember.social"
          />
        </div>
      </div>
    </section>
    <!-- End Team Section -->
  </main>
  <!-- ======= Footer ======= -->
  <footer id="footer" ref="contacts">
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-12 footer-contact">
            <h3>Gallagher</h3>
            <h4>Perth</h4>
            <p>
              Level 15, QV1 Building, 250 St Georges Terrace
              <br />
              Perth WA 6000<br />
              PO Box 7415<br />
              Cloisters Square WA 6850 <br /><br />
              <strong>Phone:</strong> <a href="tel:1300557782">1300 557 782</a
              ><br />
              <strong>Email: </strong>
              <a href="mailto:super-service@ajg.com.au"
                >super-service@ajg.com.au</a
              >
              <br />
            </p>
          </div>

          <div class="col-md-4 col-sm-12 footer-contact extra-top">
            <h4>Sydney</h4>
            <p>
              Level 16, 100 Arthur St
              <br />
              North Sydney NSW 2060<br />
              PO Box 1898<br />
              North Sydney NSW 2060 <br /><br />
            </p>
          </div>

          <div class="col-md-4 col-sm-12 footer-contact extra-top">
            <h4>Brisbane</h4>
            <p>
              Level 15, 144 Edward Street
              <br />
              Brisbane QLD 4000<br />
              GPO Box 248<br />
              Brisbane QLD 4001 <br /><br />
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container footer-bottom clearfix">
      <div class="copyright">
        &copy; Copyright
        <strong
          ><span>{{ clientConfig.content.footerClientName }}</span></strong
        >. All Rights Reserved<br />
        Gallagher Benefit Services Pty Ltd | ABN 49 611 343 803 | AFSL 488001<br /><br />
        <a target="_blank" href="https://www.ajg.com/au/privacy-policy/"
          >Privacy Policy</a
        >
        <br />
        <a
          target="_blank"
          href="https://www.ajg.com/au/policies-and-codes/benefits-legal-regulatory-information/"
          >Legal and Regulatory Information</a
        >
      </div>
      <div class="credits mr-3">
        Designed by
        <a target="_blank" href="https://www.gallaghercommunications.com.au/"
          >Gallagher Communications</a
        >
      </div>
    </div>
  </footer>
  <!-- End Footer -->
</template>
